import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

// i18nexus version number specified at https://app.i18nexus.com/#export which may be changed as needed
const version = 120;
const apiKey = process.env.REACT_APP_I18NEXUS_API_KEY;
const namespaces = {
    default: "default",
    affirmativeDefenses: "affirmativeDefenses",
};

/**
 * Gets an object containing the URLs used to access i18Nexus.
 * Exported so that non-app code can reliably get the correct urls.
 * @param {string} [lng] - The language. Defaults to the i18nexus template placeholder.
 * @param {string} [ns] - The namespace. Defaults to the i18nexus template placeholder.
 * @returns {{dev : string, prod: string}} - Object containing urls to get strings from.
 */
const getI18NexusUrls = (lng = "{{lng}}", ns = "{{ns}}") => {
    return {
        dev: `https://api.i18nexus.com/project_resources/translations/${lng}/${ns}.json?api_key=${apiKey}`,
        prod: `https://cdn.i18nexus.com/versions/${version}/translations/${lng}/${ns}.json?api_key=${apiKey}`,
    };
};

/**
 * Get i18next configuration
 * @param {string} loadPath i18next URL
 * @returns {object} i18next config
 */
const i18config = (loadPath) => ({
    fallbackLng: "en",
    supportedLngs: ["es", "en"],
    nonExplicitSupportedLngs: true,

    ns: Object.values(namespaces),
    defaultNS: namespaces.default,

    keySeparator: false,

    backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
            {
                defaultVersion: version,
            },
            {
                loadPath: loadPath,
            },
        ],
    },
});

/**
 * Initialize the i18next instance.
 * This pattern diverges from the usage pattern described in the i18next documentation.
 * The pattern from the documentation recommends running the body of this method at the top-level of the file,
 * which results in coupling the import of this file with the initialization of the i18next instance.
 * The pattern here defers initialization of the i18next instance so that client code may decide when initialization happens.
 * This was implemented specifically to allow cypress tests to declare network intercepts before initializing the i18next instance.
 * See cypress/support/component.js.
 * @param {string} url - The URL template used to request strings.
 */
const i18NextInit = (url) => {
    // eslint-disable-next-line import/no-named-as-default-member
    i18next.use(ChainedBackend).use(LanguageDetector).use(initReactI18next).init(i18config(url));
};

export { i18config, i18NextInit, getI18NexusUrls, namespaces };
